import React, { useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import Scrollspy from "react-scrollspy";

const StyledScrollNavigation = styled.div`
  .scroll-navigation {
    ul {
      ${tw`md:space-y-5`}
      li {
        ${tw`text-sm uppercase flex items-center relative `}
        &.is-current {
          a {
            ${tw`text-primary-500`}
          }
          &:before {
            ${tw`w-full md:w-6`}
          }
        }
        &:before {
          content: "";
          ${tw`h-px w-0 bg-primary-500 transition-all duration-300 ease-linear bottom-0 md:bottom-auto left-0 md:-left-9 absolute`}
        }
        a {
          ${tw`block no-underline text-primary-500/50`}
          &:hover {
            ${tw`text-primary-500`}
          }
        }
      }
    }
  }
`;

const ScrollNavigation = ({ children }) => {
  const [setActive, setActiveState] = useState("");

  const clickHandler = () => {
    setActiveState(setActive === "" ? "active" : "");
  };

  return (
    <StyledScrollNavigation
      className={`sticky top-18 z-10 -mx-6 bg-primary-50 py-5 md:top-40 md:col-span-3 md:col-start-1 md:mx-0 md:py-0 ${setActive}`}
      onKeyDown={clickHandler}
      onClick={clickHandler}
    >
      <nav className="scroll-navigation md:sticky md:top-40">
        <Scrollspy
          items={[
            "section-1",
            "section-2",
            "section-3",
            "section-4",
            "section-5",
          ]}
          offset={-100}
          currentClassName="is-current"
          className="scrollspy flex w-full flex-row items-center justify-center space-x-6 md:flex-col md:items-start md:space-x-0"
        >
          {children}
        </Scrollspy>
      </nav>
    </StyledScrollNavigation>
  );
};

export default ScrollNavigation;
